import React from "react";
import styles from '../css/Header.module.scss';

const Header = React.forwardRef((props,ref)=>{
  return (
      <div className={styles.section}>
          <div className={styles.title}>
              田间可移动智能监测调查工具
          </div>
      </div>
  );
})

export default Header;
