import styles from '../css/SaaS.module.scss';
import React, {useEffect} from "react";
import { animated , useSpring} from '@react-spring/web'
import {useScrollPagePresent} from "../hooks/useScrollPersent";
import { Scrollbar, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

const partList = [
    {name: 'AR 眼镜', imgUrl: 'glass.svg', width: 360, height: 239},
    {name: '用户指南', imgUrl: 'userGuide.svg', width: 169, height: 224},
    {name: '数据线', imgUrl: 'typec.svg', width: 169, height: 224},
    {name: '电池充电器', imgUrl: 'charge.svg', width: 187, height: 117},
    {name: '电池', imgUrl: 'buttery.svg', width: 190, height: 53},
    {name: '包装盒', imgUrl: 'box.svg', width: 172, height: 100},
]

const Section = React.forwardRef((props,ref)=>{
    const [imageBoxStyle, imageBoxApi] = useSpring(() => ({
        x: 0,
    }))
    const [topStyles, topBoxApi] = useSpring(() => ({
        opacity: 0,
        y: 0,
    }))

    const { process } = useScrollPagePresent({
        ref: ref,
        pageIndex: props.pageNow,
    })

    useEffect(() => {
        // console.log(process)
        topBoxApi.start({ opacity: process * 4, y: -100 * process })
        imageBoxApi.start({ x: -(804 * 2) *  Math.max(process - 0.25, 0)})
    }, [process, topBoxApi, imageBoxApi]);

  return (
      <div className={styles.section}>
          <div className={styles.bg}

          ></div>
          <animated.div className={styles.title} style={topStyles}>
              <div>JINHE AR GLASS</div>
              <div>软件服务</div>
          </animated.div>
          <div className={styles.content}>
              <animated.div className={styles.imgBox} style={imageBoxStyle}>
                  <img className={styles.img} src={[require('../assets/service.png')]} alt=""/>
              </animated.div>
          </div>
          <div className={styles.part3}>
              <div className={styles.part3Container}/>
          </div>
      </div>
  );
})

export default Section;
