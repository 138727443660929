import {useScroll} from "ahooks";

export const useScrollPagePresent = ({ref, pageIndex}) => {
    const position = useScroll(ref)
    const top = position?.top || 0

    const clientEle = ref?.current?.children?.[pageIndex - 1]
    const scrollYProgress = top / (ref?.current?.scrollHeight - window.innerHeight)
    if (clientEle) {
        const {offsetHeight, offsetTop} = clientEle
        const clientHeight = window.innerHeight
        const process = (top - offsetTop + clientHeight) / (offsetHeight)
        return {
            // 元素在视口内
            inView: (offsetTop - offsetHeight) < top && top < (offsetTop + offsetHeight),
            process: Math.min(1, Math.max(0, process)),
            scrollYProgress,
            clientHeight,
            offsetTop,
            offsetHeight,
            scrollY: top
        }

    }
    return {
        inView: false,
        process: 0,
        scrollYProgress,
    }

}