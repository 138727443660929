import React, {useState, useEffect} from "react";
import axios from "axios";
// import { encodeURIComponent } from 'react'; 
import styles from '../css/contactUs.module.scss';
import {animated, useSpring} from '@react-spring/web'
import {useScrollPagePresent} from "../hooks/useScrollPersent";

// 导入encodeURIcomponent
window.encodeURIComponent = window.encodeURIComponent || require('encodeurl').encodeURIComponent;
 

const ContactUs = React.forwardRef((props,ref)=>{
    const [formData, setFormData] = useState({ name: '', phone: '' });
    const [topStyles, topBoxApi] = useSpring(() => ({
        opacity: 0,
        y: 0,
    }))

    const { process } = useScrollPagePresent({
        ref: ref,
        pageIndex: props.pageNow,
    })
 
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
   
    const handleSubmit = (e) => {
      e.preventDefault();
      // 这里可以添加你的提交逻辑，比如发送数据到服务器
      addContactPerson()
    };

    const addContactPerson = ()=>{
        //¬千里眼官网意向联系人请求接口测试
        // let reqBody= {
        //     userName:"张三",
        //     tele:"18994215239"
        // }
        //这里的请求头需要添加一个属性auth，进行验证。XXJTT.J/
        //auth =reqBody.userName+"jinhetech"转成base64
        console.log('formData:', formData)
        console.log(formData.name)
        const auth = btoa(unescape(window.encodeURIComponent(`${formData.name}jinhetech`))); //
        console.log(auth)
        const url = 'http://jinjing.jinhetech:28004/contact-person/add'
        axios.post(url,
            formData,
            { 
                headers: { 'auth': auth } 
            }).then(res=>{
                console.log(res)
            }).catch(err=>{
                console.log(err)
            })
    }

    useEffect(() => {
        if(process > 0 && process < 0.5){
            topBoxApi.start({ opacity: process, y: -100 * process }) 
        } else if(process> 0.6 && process < 1){
            topBoxApi.start({ opacity: Math.min(process+0.1,1), y: Math.max(-100 * (process+0.1),-100) }) 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [process]);
  return ( 
      <div className={styles.section}>
          <animated.div className={styles.animateDiv} style={topStyles}>
              <div className={styles.title}>CONTACT US</div>
              <div className={styles.title1}>联系我们</div>
          </animated.div>
          <div className={styles.subtitle}>
              欢迎关注微信公众号(金禾天成)获取我们的最新动态，如果需要购买产品可联系010-82837690,或在下方输入您的信息，以便我们进一步与您沟通。
        </div>
        <div className={styles.liBox}>
          <input 
            name="name"
            type="text"
            value={formData.name}
            onChange={handleChange}
            className={styles.name}
            placeholder='请输入您的姓名'
          />
          <input 
            name="phone" 
            type="phone"
            className={styles.phone} 
            value={formData.phone}
            onChange={handleChange}
            placeholder='请输入您的手机号'
            />
          <button className={styles.btn} onClick={handleSubmit}>提交信息</button>
        </div>
      </div>
  );
})
  
export default ContactUs;
