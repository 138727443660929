import styles from '../css/ProductFeature.module.scss';
import React, {useEffect} from "react";
import { animated, useSpring} from '@react-spring/web'
import FeatureImage from '../assets/feature1.jpg'
import FeatureImage2 from '../assets/feature2.jpg'
import FeatureImage3 from '../assets/feature3.jpg'
import FeatureImage4 from '../assets/feature4.jpg'
import {useScrollPagePresent} from "../hooks/useScrollPersent";
import classNames from "classnames";

const Config = [
    {
        text: '语音智能交互',
        image: FeatureImage,
        fromStyle: {},
        endStyle: {}
    },
    {
        text: '精准模型识别',
        image: FeatureImage2
    },
    {
        text: '一键数据汇算',
        image: FeatureImage3
    },
    {
        text: '在线专家指导',
        image: FeatureImage4
    }
]

const Screen = React.forwardRef((props, ref) => {
    const {pageNow} = props

    const [topStyles, topBoxApi] = useSpring(() => ({
        opacity: 0,
        y: 0,
    }))

    const {process} = useScrollPagePresent({
        ref: ref,
        pageIndex: pageNow,
    })

    useEffect(() => {
        if(process > 0 && process < 0.1){
            topBoxApi.start({ opacity: process+0.1 , y: -60 * (process+0.1) })
        }else if(process > 0.1 && process < 0.3){
            topBoxApi.start({ opacity: Math.min(process+0.7, 1) , y: -60 * Math.min((process+0.7), 1) })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [process]);

    return (
        <div className={styles.section}>
            <animated.div className={styles.top} style={topStyles}>
                <div className={styles.title}>JINHE AR GLASS</div>
                <div className={styles.title}>产品特点</div>
            </animated.div>
            <div className={styles.tabsBox}>
                {
                    Config.map((item, index) => {
                        const isActive = Math.floor(process * 4) - 1 === index

                        return (<div className={classNames(styles.tabItem, isActive && styles.tabItemActive)}
                                     key={item.text}>
                            <div className={styles.tabItemTitle} style={{
                                marginTop: index * 68
                            }}>{item.text}</div>
                            <div className={styles.tabItemImage}>
                                <img src={item.image} alt=""/>
                            </div>
                        </div>)
                    })
                }
            </div>
        </div>

    );
})

export default Screen;
