import styles from '../css/tenScreen.module.scss';
import React from "react";

const ThreeScreen = React.forwardRef((props,ref)=>{

  return ( 
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.title}>JINHE AR GLASS</div>
        <div className={styles.title}>智能AR眼镜数字农业创新应用平台</div>
        <ul className={styles.lis}>
          <li>软硬件一体化的农事生产辅助决策解决方案</li>
          <li>集合AR智能硬件</li>
          <li>定制化场景应用软件</li>
          <li>一站式数据管理平台</li>
        </ul>
      </div>
    </div>
  );
})
  
export default ThreeScreen;
