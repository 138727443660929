import styles from '../css/elevenScreen.module.scss';
import React, {useEffect} from "react";
import { animated , useSpring} from '@react-spring/web'
import {useScrollPagePresent} from "../hooks/useScrollPersent";

const Screen = React.forwardRef((props,ref)=>{
  const certList = [1,2,3,4]
  const [topStyles, topBoxApi] = useSpring(() => ({
    opacity: 0,
    y: 100,
  }))

  const { process } = useScrollPagePresent({
    ref: ref,
    pageIndex: props.pageNow,
  })

  useEffect(() => {
    if(process > 0.1 && process < 0.5){
      topBoxApi.start({ opacity: process * 2, y: -50 * process })
    }else if(process > 0.5 && process <= 1){
      topBoxApi.start({ opacity: Math.min(1, process + 0.8), y: -50 * Math.min(1, process + 0.8) })
    }
  })

  return ( 
    <div className={styles.section}>
      <animated.div className={styles.title} style={topStyles}>
        <div>JINHE AR GLASS</div>
        <div>资质证书</div>
      </animated.div>
      <div className={styles.liBox}>
            {
              certList.map(index => {
                return <img className={styles.imgItem} key={index} src={[require(`../assets/cert${index}.png`)]} alt=""/>
              })
            }
      </div>
    </div>
  );
})
  
export default Screen;
