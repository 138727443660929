import React from "react";
import styles from '../css/foot.module.scss';
import phone from "../assets/iconPhone.svg"
import wx from "../assets/iconWX.svg"
import email from "../assets/iconEmail.svg"
import QRCode from "../assets/QRCode.svg"


const Foot = React.forwardRef((props,ref)=>{
    const handleClick = () => {
        window.open("http://www.jinhetech.com/")
    }

  return ( 
      <div className={styles.section}>
          <div className={styles.content}>
              <div className={styles.left}>
                  <div className={styles.info1}>数智助农 政通富民</div>
                  <div className={styles.info2}>成为数智农业的卓越践行者</div>
                  <div className={styles.company} onClick={handleClick}>北京金禾天成科技有限公司</div>
                  <div className={styles.address}>地址：北京市东城区东直门外大街48号东方银座写字楼20层20F</div>
              </div>
              <div className={styles.center}>
                <div>合作与服务</div>
                  <div className={styles.contact}>
                      <div>
                          <img className={styles.img} src={phone} alt=""/>
                          <div>010-82837690</div>
                      </div>
                      <div>
                          <img className={styles.img} src={wx} alt=""/>
                          <div>13366729816</div>
                      </div>
                      <div>
                          <img className={styles.img} src={email} alt=""/>
                          <div>13366729816</div>
                      </div>
                  </div>
              </div>
              <div className={styles.right}>
                  <img className={styles.QRCode} src={QRCode} alt=""/>
                  <div>公司官网</div>
              </div>
          </div>
          <div className={styles.address1}>
              © Copyright 北京金禾天成有限公司 版权所有 2016-2023. All Rights Reserved.
          </div>
      </div>
  );
})
  
export default Foot;
