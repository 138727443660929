import {useRef} from 'react';
import './App.css';
import Header from './components/Header'
import FirstScreen from './components/FirstScreen'
import SecondScreen from './components/SecondScreen'
import ThreeScreen from './components/ThreeScreen'
import UseScene from './components/UseScene'
import ProductPresent from './components/ProductPresent'
import ProductFeature from './components/ProductFeature'
import UserCase from './components/UserCase'
import SaaS from './components/SaaS'
import PartList from './components/PartList'
import TenScreen from './components/TenScreen'
import ElevenScreen from './components/ElevenScreen'
import ContactUs from './components/ContactUs'
import Foot from './components/Foot'
// import {Parallax, ParallaxLayer, IParallax} from '@react-spring/parallax'

const components = [
    Header,
    FirstScreen,
    SecondScreen,
    ThreeScreen,
    UseScene,
    ProductPresent,
    ProductFeature,
    UserCase,
    SaaS,
    PartList,
    TenScreen,
    ElevenScreen,
    ContactUs,
]

function App() {
    const ref = useRef(null)

    return (
        <div className="App" ref={ref}>
            {
                components.map((Component, index) => {
                    return <Component key={index} ref={ref} pageNow={index + 1} pageCount={components.length}/>
                })
            }
            <Foot key={'foot'} />
        </div>
    );
}

export default App;
