import styles from '../css/UseScene.module.scss';
import React, {useEffect} from "react";
import { animated, useSpring } from '@react-spring/web'
import Scene1 from '../assets/sence1.png'
import Scene2 from '../assets/sence2.png'
import Scene3 from '../assets/sence3.png'
import {useScrollPagePresent} from "../hooks/useScrollPersent";

const Config = [
    {
        title: '病虫害专业田间调查',
        img: Scene1
    },
    {
        title: '数字农业物联中控',
        img: Scene2
    },
    {
        title: '农事活动智能工具',
        img: Scene3
    }
]

const Screen = React.forwardRef((props, ref) => {

    const [imageBoxStyle, imageBoxApi] = useSpring(() => ({
        x: 0,
    }))
    const [topStyles, topBoxApi] = useSpring(() => ({
        opacity: 0,
        y: 0,
    }))

   const { process } = useScrollPagePresent({
        ref: ref,
        pageIndex: props.pageNow,
   })

    useEffect(() => {
        if(process > 0 && process < 0.05){
            topBoxApi.start({ opacity: (process*2), y: -100 * (process* 2) })
        } else if(process > 0.05 && process < 0.15){
            topBoxApi.start({ opacity: (process*3), y: -100 * (process* 3) })
        } else if(process> 0.15 && process < 0.17){
            topBoxApi.start({ opacity: (process*4), y: -100 * (process* 4) })
        } else if(process> 0.17 && process < 1){
            topBoxApi.start({ opacity: Math.min(process*5,1), y: Math.max(-100 * (process* 5),-100) })
        }
        imageBoxApi.start({ x: -(804 * 1.2) *  Math.max(process - 0.25, 0)})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [process]);

    return (
        <div className={styles.section}>
            <animated.div className={styles.top} style={topStyles}>
                <div className={styles.title}>JINHE AR GLASS</div>
                <div className={styles.title}>使用场景</div>
            </animated.div>
            <div className={styles.content}>
                <animated.div className={styles.imgsBox} style={imageBoxStyle}>
                    {
                        Config.map((item, index) => {
                            return (
                                <div className={styles.imgBoxItem}>
                                    <img className={styles.img} src={item.img} alt=""/>
                                </div>
                            )
                        })
                    }
                </animated.div>
            </div>
            <div className={styles.part3}>
                <div className={styles.part3Container} />
            </div>
        </div>

    );
})

export default Screen;
