import styles from '../css/secondScreen.module.scss';
import React, {useEffect} from "react";
import {animated , useSpring} from '@react-spring/web'
import {useScrollPagePresent} from "../hooks/useScrollPersent";

// forwardRef 把父元素的ref传递过来
const SecollScreen = React.forwardRef((props, ref) =>  {
  const {pageNow} = props

  const [leftStyles, leftApi] = useSpring(() => ({
    opacity: 0,
    x: 0,
    y: 0
  }))
  const [rightStyles, rightApi] = useSpring(() => ({
    opacity: 0,
    x: 0,
    y: 0
  }))

  const { process } = useScrollPagePresent({
    ref: ref,
    pageIndex: pageNow,
  })

  useEffect(() => {
    if (process === undefined) return
      leftApi.start({ opacity: process, x: 100 * process, y: 100 * process })
    if(process > 0.6 && process < 1){
      rightApi.start({ opacity: process, x: -100 * process, y: -100 * process })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process]);

  return (
    <div className={styles.secondSection}>
      <animated.div className={styles.left}  style={leftStyles}>
        <div className={styles.title}>金禾天成</div>
        <img className={styles.fontImg} src={require("../assets/whiteFont.jpg")} alt=""/>
        <div className={styles.introduce1}>您身边的智能帮手</div>
        <div className={styles.introduce}>解放双手 智能交互</div>
      </animated.div>
      <animated.div className={styles.content}  style={rightStyles}>
        本设备基于工业AR眼镜，加载Al、语音识别、图像识别、边缘计算和LLM等技术，集成卷积神经网络等优化算法与模型。既服务于农作物病虫害专业田间调查工作，实现病虫害自动识别、自动分类计数，与国家、省级相关报表系统集成，数据一键上传，为植保专业人员提供智能辅助工具，工作效率倍增；又服务于数字农业相关领域，成为农业物联网与智能农机的智慧控制中心，成为农事活动智能采集工具与管理工作驾驶仓。
      </animated.div>
    </div>
  );
})



export default SecollScreen
